import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import Layout from "../../components/layout"
import AboutComponent from "../../components/pages/main/about"
import ReviewComponent from "../../components/pages/main/review"
import FeaturesComponent from "../../components/pages/main/landing-features"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import FeaturesGridComponent from "../../components/pages/features/grid"
import styles from "./feature.module.scss"
import GalleryComponent from "../../components/pages/features/gallery"
import FeatureAccordion from "../../components/pages/main/accordion"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs';

const clientsAccordionData = [
  {
    name: "insteadOfTable",
    title: "Смогу ли я использовать программу учета данных клиентов вместо электронных таблиц?",
    content: `
      <p>Параплан CRM полностью подходит как система учета клиентов. Кроме этого, если база клиентов или лидов уже существует, запросите шаблон у менеджера Параплан для переноса текущей базы. Далее заполните полученный документ и направьте его в Службу заботы. Специалист сможет загрузить клиентскую базу в CRM-систему и сэкономит вам время.</p>
    `,
  },
  {
    name: "security",
    title: "Каким образом защищается информация в Параплан CRM-системе для детских центров?",
    content: `
      <p>Ваши данные находятся под виртуальным замком. Инфраструктура Параплан CRM находятся на территории РФ, также продукт зарегистрирован в Реестре отечественного ПО. В СRМ-системе вся информация, связанная с ведением клиентской базы, надежно шифруется. Клиентская база в программе Параплан CRM защищена от удаления, и все онлайн данные копируются в резервное хранилище. Вы можете безопасно вести учет клиентов и быть спокойными за их данные. Кроме этого, никто из сторонних лиц не сможет скачать базу из сервиса учета клиентов Параплан. Владелец аккаунта может ограничить возможность скачивания клиентской базы из CRM любому пользователю.</p>
    `,
  },
  {
    name: "tools",
    title: "Какие инструменты для взаимодействия с клиентами есть в CRM-системе учета клиентов?",
    content: `
      <p>Для полноценной работы с клиентами вашего бизнеса в Параплан CRM есть множество инструментов. В системе есть раздел “Клиенты”. Его также можно переименовать в “Ученики” или “Спортсмены” по вашему желанию. В данном разделе хранится вся информация по каждому клиенту. В профиле клиента отображаются занятия, которые посещает ученик, и статусы каждого посещения (болел, пропустил, посетил и т.д). Также есть возможность в один клик оформить абонемент, посмотреть развернутую отчетность по ученику, не покидая страницы. Вдобавок в профиле клиента администратор центра может вести переписку в мессенджере, просматривать историю звонков клиенту, ставить задачу, вносить платеж. Все действия в рамках одного окна - до 50% экономия времени на административных задачах.</p>
    `,
  }
]

const ClientsFeaturePage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Возможности', url: '/feature'},
    { name: 'Учет клиентов', url: ''}
  ];
  return (
  <Layout className={styles.clients} headerColor={"#319DED"} page={"clients"}>
    <SEO 
      title="Система учета клиентов в CRM-системе Параплан"
      description="Система учета клиентов в CRM Параплан поможет автоматизировать работу с клиентами в различных отраслях бизнеса без оплаты за пользователей."
      url={"https://paraplancrm.ru" + props.location.pathname}
    />
    <HeroFeaturePage
      name={"clients"}
      htmlTitle='Управляйте <br/> клиентской базой'
      description='Онлайн программа учета клиентов поможет выстроить доверительные отношения, узнать интересы и попасть в&nbsp;ожидания клиентов.'
      color='#319DED'
      image={require("../../images/cover/clients.png")}/>
    <Breadcrumb crumbs={ crumbs } arrowcolor={"blue"}/>
    <AboutComponent
      title='Подружитесь <br/> с клиентами'
      description='Поздравляйте со значимыми событиями и поддерживайте постоянный контакт с помощью CRM-системы учета клиентов.'
      image={require("../../images/screenshot/clients/clients-1.png")}
      imageWidth={595}
      imageHeight={453}
      bgSize={"position-right"}
      reverse={true}
      color='#FFC800'/>
    <AboutComponent
      title='Время на вашей <br/> стороне'
      description='Поиск клиента занимает секунды, а вся важная информация находится в электронной карточке.'
      image={require("../../images/screenshot/clients/clients_04@2x.png")}
      imageWidth={670}
      imageHeight={473}
      bgSize={"skewed-left"}
      color='#6D20D2'/>
    {/*<FreeSummer topInd="60"/>*/}
    <Discount/>
    <GalleryComponent
      title={"Делайте больше продаж"}
      subtitle={"Система Параплан CRM поможет автоматизировать учет клиентов вашего бизнеса."}
      galleryView={"img"}
      data={[
        {
          name: "tasks",
          title: "Задачи для администраторов",
          content: "Ставьте задачи для администраторов в системе и контролируйте процесс выполнения онлайн.",
          image: require("../../images/screenshot/clients/clients_gallery_tasks@2x.png"),
        },
        {
          name: "statuses",
          title: "Статусы клиентов",
          content: "Удобный фильтр статусов клиентов (активный, архивный, предварительная запись) позволит вам эффективно работать с базой.",
          image: require("../../images/screenshot/clients/clients_gallery_status@2x.png"),
        },
        {
          name: "bookings",
          title: "Предварительная запись",
          content: "Максимально используйте пространство в своих группах. Вносите в список ожидания потенциальных клиентов.",
          image: require("../../images/screenshot/clients/clients_gallery_booking@2x.png"),
        },
        {
          name: "expiring",
          title: "Истекающие абонементы",
          content: "Система напомнит клиентам об окончании действия абонемента и поможет оформить новый заблаговременно.",
          image: require("../../images/screenshot/clients/clients_gallery_expiring@2x.png"),
        },
      ]}
    />
    <FeaturesGridComponent
      title={"Повышайте лояльность клиентов"}
      subtitle={"Каждый клиент имеет значение. Параплан поможет вам стать заботливыми в глазах клиента за счет персональных Email и СМС рассылок."}
      features={[
        {
          name: "Обратная связь",
          text: "Получите обратную связь после окончания курса или предложите новый.",
          image: require("../../images/icons/clients_feedback.svg"),
        },
        {
          name: "Система лояльности",
          text: "Дарите вашим клиентам скидки и специальные предложения.",
          image: require("../../images/icons/clients_sale.svg"),
        },
        {
          name: "Возврат клиентов",
          text: "Напомните о себе после длительного отсутствия клиента.",
          image: require("../../images/icons/clients_notification.svg"),
        },
        {
          name: "Первая покупка",
          text: "Поблагодарите клиента после совершения первой покупки.",
          image: require("../../images/icons/clients_first-buy.svg"),
        },
      ]}/>
    <ReviewComponent author='Борис Чернов, директор детского клуба «Радуга»'
                     color={"#E75D49"}
                     className={styles.review}>
      С системой Параплан мы, наконец, перестали терять клиентов. Мы тратили много денег на рекламу и привлечение новых,
      при этом не уделяли должного внимания текущим клиентам. В результате использования программы Параплан, нам удалось
      сократить отток клиентов и увеличить прибыль на 40%.
    </ReviewComponent>
    <FeaturesComponent title="Дополнительные возможности"/>
    <ContactFormComponent/>
    <FeatureAccordion
            data={clientsAccordionData}
            title='Эффективный учет клиентов в CRM-системе'
            descr='Почему в детском центре важно вести учет клиентов в CRM? CRM нужна детскому образовательному центру, в первую очередь, как инструмент взаимодействия с клиентами, хранения клиентской базы и ведения истории работы с клиентом. Наличие программы для учета клиентов является гарантом безопасности базы и продуктивной работы с ней. CRM включает в себя инструменты автоматизации учета клиентов: специальные карточки учеников, дополнительные поля в профиле клиента, множество фильтров для сортировки базы, проверку на дубли и многое другое. Такие системы учета клиентов, как CRM Параплан способны вывести клиентское обслуживание на новый уровень и отстроиться от конкурентов.'
          />
  </Layout>
)}

export default ClientsFeaturePage
